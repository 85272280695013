import React from 'react';
import styled from 'styled-components';

import Container from './Container';
import FadeEntry from './FadeEntry';
import Section from './Section';
import Title from './Title';

const Outer = styled(Section)`
	background: ${({ theme }) => theme.color.blue};
	text-align: left;

	.w-aside__main {
		flex: 1;
		max-width: 680px;
		padding-right: 35px;
		color: ${({ theme }) => theme.color.white};
		font-size: 20px;

		h2 {
			margin-bottom: 20px;
		}
	}
	
	.w-aside__aside {
		position: relative;
		width: 305px;
		align-self: flex-start;
		margin-top: 73px;
		font-size: 20px;

		>div {
			position: relative;
			padding: 28px 27px;
			background: #fff;
			z-index: 1;
		}

		h3 {
			margin-bottom: 11px;
		}
		
		&:after {
			content: '';
			position: absolute;
			top: 15px;
			left: 15px;
			width: 100%;
			height: 100%;
			z-index: 0;
			background: ${({ theme }) => theme.color.brand};
		}
	}

	@media (max-width: 767px) {
		.w-aside__main {
			padding: 0;
		}

		.w-aside__aside {
			width: calc(100% - 15px);
			margin-top: 30px;
		}
	}
`;

const Inner = styled(Container)`
	display: flex;
	justify-content: space-between;

	@media (max-width: 767px) {
		flex-wrap: wrap;
	}
`;

const WithAside = ({ title, text, asideTitle, asideText }) => {
	return (
		<Outer>
			<Inner>
				<FadeEntry className="w-aside__main">
					<Title 
						as="h2"
						white
					>{ title }</Title>
					<div dangerouslySetInnerHTML={{ __html: text }} />
				</FadeEntry>

				{ asideText ? (
					<FadeEntry 
						className="w-aside__aside"
						as="aside"
					>
						<div>
							<Title as="h3">{ asideTitle }</Title>
							<div dangerouslySetInnerHTML={{ __html: asideText }} />
						</div>
					</FadeEntry>
				) : null }
			</Inner>
		</Outer>
	);
}

export default WithAside;