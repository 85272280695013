import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from './Container';
import FadeEntry from './FadeEntry';
import Section from './Section';
import Title from './Title';

const Outer = styled(Section)`
	background: ${({ theme }) => theme.color.blue};
	color: ${({ theme }) => theme.color.white};
	text-align: left;

	.two-cols__left,
	.two-cols__right {
		width: 45%;
	}

	h2 {
		margin-bottom: 20px;
	}

	a {
		color: #fff;
	}

	.two-cols__right {
		>* +* {
			margin-top: 20px;
		}
	}

	@media (max-width: 767px) {
		.two-cols__left,
		.two-cols__right {
			width: 100%;
		}

		.two-cols__right {
			margin-top: 20px;
		}
	}
`;

const Inner = styled(Container)`
	display: flex;
	justify-content: space-between;

	@media (max-width: 767px) {
		flex-wrap: wrap;
	}
`;

const TwoCols = ({ title, text, right }) => {
	return (
		<Outer>
			<Container>
				<Title 
					as="h2"
					white
				>{ title }</Title>
			</Container>
			
			<Inner>
				<FadeEntry className="two-cols__left">
					<div 
						className="user-content" 
						dangerouslySetInnerHTML={{ __html: text }} 
					/>
				</FadeEntry>

				<FadeEntry className="two-cols__right">
					{ right() }
				</FadeEntry>
			</Inner>
		</Outer>
	);
}

TwoCols.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	right: PropTypes.func,
}

export default TwoCols
